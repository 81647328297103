import { createApi } from '@reduxjs/toolkit/query/react';
import { customBaseQuery } from 'common/api/customBaseQuery';
import { withFilters } from 'common/api/utils/apiFunctions';
import { FilterCategoryOptions } from 'common/hooks';
import { Grower, PaginatedResult, PaginationQueryParams } from 'common/models';
import { DateWithoutTime } from 'common/models/dateType';
import { GetGrower } from './dto/get-grower.dto';

export type GrowerUpdateRequest = {
  id: number;
  parentGrowerName: string | null;
  managerName: string | null;
  grpNumber: number | null;
  permitNumber: number | null;
  ggnNumber: number | null;
  certExp: DateWithoutTime | null;
  gapStatus: string | null;
  gapCertNotes: string | null;
  managerCell: string | null;
  notes: string | null;
  secondGrower: string | null;
};

type GrowerFilterQueryParams = {
  filters: FilterCategoryOptions[];
};

const growersBaseUrl = '/growers';

const growerTag = 'Grower';
const growersTag = 'Growers';

export const growerApi = createApi({
  reducerPath: 'growerApi',
  baseQuery: customBaseQuery,
  refetchOnReconnect: true,
  tagTypes: [growerTag, growersTag],

  endpoints: builder => ({
    getGrowers: builder.query<
      PaginatedResult<Grower>,
      PaginationQueryParams & GrowerFilterQueryParams
    >({
      query: prm => withFilters(prm, growersBaseUrl),
      providesTags: (_, err) => (err ? [] : [growersTag]),
    }),

    getAllGrowerData: builder.query<Grower[], void>({
      query: () => ({
        url: `${growersBaseUrl}/all`,
        method: 'GET',
      }),
      // Don't provide tags as we always want the latest from the server.
    }),

    getGrowerById: builder.query<GetGrower, { id: number; seasonId: number }>({
      query: args => `${growersBaseUrl}/${args.seasonId}/${args.id}`,
      providesTags: (_, err, args) =>
        err ? [] : [{ type: growerTag, id: `/${args.seasonId}/${args.id}` }],
    }),

    updateGrower: builder.mutation<Grower, GrowerUpdateRequest>({
      query: ({ id, ...payload }) => ({
        url: `${growersBaseUrl}/${id}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: (_, err, arg) =>
        err ? [] : [growersTag, { type: growerTag, id: arg.id }],
    }),
  }),
});

export const {
  useGetGrowersQuery,
  useGetAllGrowerDataQuery,
  useGetGrowerByIdQuery,
  useUpdateGrowerMutation,
} = growerApi;
