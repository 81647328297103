import { yupResolver } from '@hookform/resolvers/yup';
import {
  BlockDetails,
  WithPartialSeasonData,
} from 'common/api/dto/get-block.dto';
import { handleError } from 'common/api/handleError';
import { DetailForm } from 'common/components/DetailControls/DetailForm';
import FormPrompt from 'common/components/FormPrompt';
import {
  LocationButton,
  validateCoordinateInfo,
} from 'common/components/LocationButton/LocationButton';
import useWindowSize from 'common/hooks/useWindowSize';
import { BlockStatus, GrowerBlock } from 'common/models';
import * as notifier from 'common/services/notification';
import { mobile } from 'common/styles/breakpoints';
import { lightGreyText, lighterNavy, orange } from 'common/styles/colors';
import { StyledFormWrapper, Title } from 'common/styles/form';
import { MobileScrollWrapper } from 'common/styles/page';
import { useRbac } from 'features/rbac';
import React, { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Constants } from 'utils/constants';
import { blockSchema } from 'utils/schemas/blockSchema';
import { BlockDetailsFields } from './BlockDetails/BlockDetailsFields';
import { BlockDetailsFooter } from './BlockDetails/BlockDetailsFooter';
import { BlockDetailsInfo } from './BlockDetails/BlockDetailsInfo';
import { MakeInactiveModal } from './BlockDetails/modals/MakeInactiveModal';
import { DetailsHeader } from './DetailsHeader';

const LargeFormWrapper = styled(StyledFormWrapper)`
  width: 100%;
  max-width: 1000px;
  @media (max-width: ${mobile}) {
    width: 100vw;
  }
`;

export const BlockInfoWrapper = styled.div.attrs(() => ({
  className: 'block-info-wrapper',
}))`
  display: flex;
  justify-content: space-between;
  .right-side-text {
    text-align: right;
  }
`;

export const BlockInfo = styled.h1`
  font-family: 'KanitBold';
  font-weight: 600;
  font-size: 24px;
  letter-spacing: 0.0075em;
  color: ${lighterNavy};
  display: flex;
  align-items: center;
  @media (max-width: ${mobile}) {
    margin: 0 5px;
    padding: 0 20px;
  }
`;

export const BlockDetailForm: React.FC<{
  block: BlockDetails<WithPartialSeasonData>;
  updater: (req: GrowerBlock) => Promise<void>;
}> = ({ block, updater }) => {
  const history = useHistory();
  const { width } = useWindowSize();
  const { userHasPermission } = useRbac();
  const isMobile = width < parseInt(mobile, 10);
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, toggleModal] = useState(false);
  const mapParams = validateCoordinateInfo(
    block.latitude,
    block.longitude,
    block.blockId,
  );
  // Remove 'blockId' since it's not needed by the backend and
  // we don't want to inadvertently save the wrong thing.
  const { blockId, ...defaultValues } = block;

  const methods = useForm<GrowerBlock>({
    defaultValues,
    resolver: yupResolver(blockSchema),
    mode: 'all',
  });

  const {
    handleSubmit,
    formState: { isSubmitting, isSubmitSuccessful, isDirty, isValid },
    reset,
    getValues,
    trigger,
  } = methods;

  const trySubmit = useCallback(
    async (evt: React.FormEvent) => {
      let isSuccessful: boolean;

      if (isDirty && isValid) {
        try {
          await handleSubmit(updater)(evt);
          notifier.showSuccessMessage('Block details updated.');
          isSuccessful = true;
        } catch (error) {
          handleError(error, Constants.errorMessages.SAVE_FAILED);
          isSuccessful = false;
        }
      } else if (!isValid) {
        trigger();
        notifier.showErrorMessage(Constants.errorMessages.VALIDATION_FAILED);
        isSuccessful = false;
      } else {
        isSuccessful = true; // No changes, continue normally.
      }

      return isSuccessful;
    },
    [isDirty, isValid, handleSubmit, updater, trigger],
  );

  // This effect clears the dirty flag.
  useEffect(() => {
    if (isSubmitSuccessful) {
      reset(getValues());
    }
  }, [isSubmitSuccessful, reset, getValues]);

  const MobileWrapper = isMobile ? MobileScrollWrapper : React.Fragment;

  return (
    <FormProvider {...methods}>
      {!isMobile && <Title>Block Detail</Title>}

      {/* Inactive Modal cannot be nested within the DetailFrom, otherwise
  the submit action propogates from the modal to the DetailForm */}
      <MakeInactiveModal
        isOpen={isModalOpen}
        closeModal={() => toggleModal(false)}
        isLoading={isLoading}
        setLoading={setIsLoading}
        contentProps={{
          growerName: block.grower?.name || '',
          blockName: block.blockName || '',
        }}
      />
      <DetailForm
        submitAction={trySubmit}
        disabled={isSubmitting}
        CustomWrapper={LargeFormWrapper}
        largeLabels
        setIsEdit={setIsEdit}
        reset={reset}
        closeHandler={() => history.push(Constants.routes.GROWERS_LIST)}
        canEdit={userHasPermission('block:edit')}
      >
        <MobileWrapper>
          <DetailsHeader
            status={
              block.seasonData ? block.seasonData.status : BlockStatus.No_Status
            }
            type='block'
            inactiveReason={block.seasonData?.reason}
          />
          <BlockInfoWrapper>
            <BlockInfo>{block.blockId}</BlockInfo>
            <BlockInfo className='right-side-text'>
              {block.blockName}
              <LocationButton
                backgroundColor={orange}
                iconColor={lightGreyText}
                mapParams={mapParams}
              />
            </BlockInfo>
          </BlockInfoWrapper>
          <BlockInfoWrapper>
            <BlockInfo>{block.grower?.growerId}</BlockInfo>
            <BlockInfo className='right-side-text'>
              {block.grower?.name}
            </BlockInfo>
          </BlockInfoWrapper>
          {block.seasonData && (
            <BlockDetailsInfo blockStatus={block.seasonData.status} />
          )}
          <BlockDetailsFields block={block} />
        </MobileWrapper>
        {userHasPermission('blockStatus:change') && !isEdit ? (
          <BlockDetailsFooter block={block} openInactiveModal={toggleModal} />
        ) : (
          <></>
        )}
        <FormPrompt isDirty={isDirty} isSubmitting={isSubmitting} />
      </DetailForm>
    </FormProvider>
  );
};
