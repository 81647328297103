import { useAppSelector } from 'app/redux';
import {
  BlockDetails,
  WithPartialSeasonData,
} from 'common/api/dto/get-block.dto';
import { UpdateBlockStatusDto } from 'common/api/dto/growerBlockApiTypes';
import {
  useAddToSeasonMutation,
  useUpdateBlockStatusMutation,
} from 'common/api/growerBlockApi';
import { handleError } from 'common/api/handleError';
import { LoadingButton } from 'common/components/LoadingButton';
import { BlockStatus } from 'common/models';
import * as notifier from 'common/services/notification';
import { mobile } from 'common/styles/breakpoints';
import { SubmitButton } from 'common/styles/button';
import { lighterNavy, orange, white } from 'common/styles/colors';
import {
  seasonSliceName,
  SeasonState,
} from 'features/navbar/components/SeasonSelector/seasonSlice';
import { Dispatch, SetStateAction, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Constants } from 'utils/constants';
import { MakeActiveModal } from './modals/MakeActiveModal';
import { MakeInactiveButton } from './modals/MakeInactiveButton';

export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  width: 100%;

  @media (max-width: ${mobile}) {
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
  }
`;

const ViewDataButton = styled.button`
  font-family: 'KanitRegular';
  font-size: 18px;
  letter-spacing: 0.12em;
  width: 336px;
  background: ${lighterNavy};
  border-radius: 4px;
  margin-bottom: 40px;
`;

export const ActionButton = styled.button.attrs({ type: 'button' })`
  font-family: 'KanitRegular';
  font-size: 18px;
  letter-spacing: 0.12em;
  border-radius: 4px;
  border: none;
  color: ${white};

  background: ${orange};
  width: 200px;
  height: 40px;
`;

const UndoActionButton = styled(ActionButton)`
  background: ${lighterNavy};
  width: 180px;
  height: 40px;
`;

interface FooterProps {
  block: BlockDetails<WithPartialSeasonData>;
  openInactiveModal: Dispatch<SetStateAction<boolean>>;
}

export const BlockDetailsFooter: React.FC<FooterProps> = ({
  block,
  openInactiveModal,
}) => {
  const blockStatus = block ? block.seasonData?.status : undefined;
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [updateBlockStatus] = useUpdateBlockStatusMutation();
  const [addToSeason] = useAddToSeasonMutation();
  const { selectedSeason } = useAppSelector<SeasonState>(
    state => state[seasonSliceName],
  );

  const closeModal = () => {
    setIsOpen(false);
  };
  const openModal = () => {
    setIsOpen(true);
  };

  const tryAddToSeason = async () => {
    try {
      setIsLoading(true);

      await addToSeason({
        seasonId: selectedSeason?.id as number,
        blockId: parseInt(id, 10),
      }).unwrap();

      notifier.showSuccessMessage('Block added successfully.');
    } catch (error) {
      handleError(error, Constants.errorMessages.UPDATE_BLOCK_FAILED);
    } finally {
      setIsLoading(false);
    }
  };

  const makeBlockActive = async () => {
    // We send the 'New' status and let the server determine if
    // the block should be given a different active status.
    const blockStatusDto: UpdateBlockStatusDto = {
      blockStatus: BlockStatus.New,
    };

    try {
      setIsLoading(true);
      const updatedBlock = await updateBlockStatus({
        seasonId: selectedSeason?.id as number,
        blockId: parseInt(id, 10),
        ...blockStatusDto,
      }).unwrap();

      setIsLoading(false);

      if (updatedBlock.status !== BlockStatus.New) {
        openModal();
      }

      notifier.showSuccessMessage(
        Constants.errorMessages.UPDATE_STATUS_SUCCESS,
      );
    } catch (error) {
      handleError(error, Constants.errorMessages.UPDATE_BLOCK_FAILED);
      setIsLoading(false);
    }
  };

  const goToEvalEstPage = () => {
    history.push(
      `${Constants.routes.EVAL_EST_MAIN}/${block.id}${Constants.routes.ESTIMATES}`,
    );
  };

  return (
    <>
      <MakeActiveModal
        isOpen={isOpen}
        closeModal={closeModal}
        contentProps={{
          growerName: block.grower?.name || '',
          blockName: block.blockName || '',
          evaluatorName:
            block.primaryEvaluator?.firstName &&
            block.primaryEvaluator?.lastName
              ? `${block.primaryEvaluator?.firstName} ${block.primaryEvaluator?.lastName}`
              : '',
        }}
      />
      <ButtonGroup>
        {
          // Add to season - only shown when block is not in the selected season.
          blockStatus === undefined && (
            <>
              <LoadingButton
                as={ActionButton}
                onClick={tryAddToSeason}
                loading={isLoading}
              >
                ADD TO SEASON
              </LoadingButton>
            </>
          )
        }

        {
          // View estimates - only shown when block is in the selected season
          // and its status is one that should have estimates.
          (blockStatus === BlockStatus.Active ||
            blockStatus === BlockStatus.Harvested) && (
            <ViewDataButton
              as={SubmitButton}
              loading='false'
              onClick={goToEvalEstPage}
            >
              VIEW ESTIMATES
            </ViewDataButton>
          )
        }

        {
          // These two buttons work in tandem to deactivate/reactivate a block -
          // one will always be shown if the block is in the selected season.
          (blockStatus === BlockStatus.New ||
            blockStatus === BlockStatus.Pending ||
            blockStatus === BlockStatus.Active ||
            blockStatus === BlockStatus.Harvested) && (
            <MakeInactiveButton openModal={openInactiveModal} />
          )
        }
        {(blockStatus === BlockStatus.Non$Producing ||
          blockStatus === BlockStatus.Withdrawn ||
          blockStatus === BlockStatus.Inactive) && (
          <>
            <LoadingButton
              as={UndoActionButton}
              loading={isLoading}
              onClick={makeBlockActive}
            >
              MAKE ACTIVE
            </LoadingButton>
          </>
        )}
      </ButtonGroup>
    </>
  );
};
